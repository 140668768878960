import * as SentryLib from '@sentry/browser';
import { Debug } from '@sentry/integrations';

/**
 * Type definition for SentryConfig
 * @typedef {Object} SentryConfig
 * @property {Object} Sentry - Sentry instance properly setup
 * @property {Function} captureException - Callback to capture exceptions manually
 */

/**
 * Configs everything related to Sentry service.
 *
 * Inspired by sentry example from Next JS
 * https://github.com/zeit/next.js/blob/canary/examples/with-sentry/utils/sentry.js
 *
 * @returns {SentryConfig}
 */
const configSentry = () => {
  const sentryOptions: SentryLib.BrowserOptions = {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    release: process.env.NEXT_PUBLIC_SENTRY_RELEASE,
    environment: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    autoSessionTracking: false,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    ignoreErrors: ['SSO-IGNORE'],
  };

  // debug errors instead of report them for non-production environments
  // or running E2E tests
  if (
    process.env.NODE_ENV !== 'production' ||
    process.env.NEXT_PUBLIC_CYPRESS_TEST_ENV
  ) {
    // Avoid sending the errors to Sentry...
    sentryOptions.beforeSend = () => null;

    // ... and dump the errors to the console instead.
    sentryOptions.integrations = [
      new Debug({
        debugger: false, // Trigger DevTools debugger instead of using console.log
      }),
    ];
  }

  SentryLib.init(sentryOptions);

  /**
   * Captures exception manually
   * @param {Error} err - Error thrown
   * @param {Object} [additionalData] - Extra data for improving error context
   * @param {Object} [additionalData.req] - Next JS request value
   * @param {Object} [additionalData.res] - Next JS response value
   * @param {Object} [additionalData.errorInfo] - Info related to React component
   * * @param {string} [additionalData.level] - Sentry logging level, one of "fatal", "critical", "error", "warning", "log", "info", and "debug".
   * @param {String} [additionalData.query] - Next JS query value
   * @param {Object} [additionalData.pathname] - Next JS pathname value
   * @param {Object} [additionalData.tags] - Tags added to Sentry event
   * @returns {String} Generated event ID
   */
  const captureException = (
    err: { message: string; statusCode: number } | any,
    additionalData: any
  ) => {
    SentryLib.configureScope(scope => {
      if (err.message) {
        // De-duplication currently doesn't work correctly for SSR / browser errors
        // so we force deduplication by error message if it is present
        scope.setFingerprint([err.message]);
      }

      if (err.statusCode) {
        scope.setExtra('statusCode', err.statusCode);
      }

      if (additionalData) {
        const {
          req = {},
          res,
          errorInfo,
          level,
          query,
          pathname,
          tags,
        } = additionalData;

        if (level) {
          scope.setLevel(level);
        }

        if (res && res.statusCode) {
          scope.setExtra('statusCode', res.statusCode);
        }

        if (typeof window !== 'undefined') {
          scope.setTag('ssr', false);
          scope.setExtra('query', query);
          scope.setExtra('pathname', pathname);

          // TODO: find proper way to set session id from our tokens here
          // https://docs.sentry.io/enriching-error-data/scopes/?platform=browser#configuring-the-scope
          //const sessionId = Cookie.get('sid');
          //if (sessionId) {
          //  scope.setUser({ id: sessionId });
          //}
        } else {
          scope.setTag('ssr', true);
          scope.setExtra('url', req.url);
          scope.setExtra('method', req.method);
          scope.setExtra('headers', req.headers);
          scope.setExtra('params', req.params);
          scope.setExtra('query', req.query);

          // TODO: find proper way to set session id from our tokens here
          // https://docs.sentry.io/enriching-error-data/scopes/?platform=browser#configuring-the-scope
          // On server-side we take session cookie directly from request
          //if (req.cookies.sid) {
          //  scope.setUser({ id: req.cookies.sid });
          //}
        }

        // Include info from React component which thrown the error within render
        if (errorInfo) {
          Object.keys(errorInfo).forEach(key =>
            scope.setExtra(key, errorInfo[key])
          );
        }

        if (tags) {
          Object.keys(tags).forEach(key => {
            scope.setTag(key, tags[key]);
          });
        }
      }
    });

    return SentryLib.captureException(err);
  };

  return { Sentry: SentryLib, captureException };
};

/**
 * @exports {SentryConfig}
 */
export const { Sentry, captureException } = configSentry();
