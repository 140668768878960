import { BoxProps, LinkProps, TextProps } from '@chakra-ui/react';

export const BoxStyles: BoxProps = {
  paddingTop: 3,
  paddingBottom: 4,
};

export const TextStyles: TextProps = {
  color: 'lightBlack',
  fontSize: 'md',
  lineHeight: '14px',
  verticalAlign: 'bottom',
};

export const LinkStyles: Omit<LinkProps, 'href'> = {
  color: 'lightBlack',
  fontSize: 'md',
  lineHeight: '14px',
};
