import { Text, Link } from '@chakra-ui/react';
import NextLink from 'next/link';
import React from 'react';

import { routes } from '~/constants/routes';
import { Sentry } from '~/types/sentry';

import Button from '../Button';
import {
  ErrorPageLayout,
  ErrorPageHeading,
  ErrorPageImage,
  ErrorPageMainContent,
  ErrorPageText,
  ErrorPageSubheading,
} from '../ErrorPage';
import LinkButton from '../LinkButton';

type Error500Props = {
  errorEventId: string | null;
};

const Error500: React.FC<Error500Props> = ({ errorEventId }) => {
  return (
    <ErrorPageLayout>
      <ErrorPageMainContent>
        <ErrorPageHeading>500. Something went wrong…</ErrorPageHeading>
        <ErrorPageSubheading>
          Sorry, something went wrong. We are working to fix this error
        </ErrorPageSubheading>
        <ErrorPageText>
          Try to reload the page, or check back later. <br />
          {!!errorEventId && (
            <Text>
              If the problem persists, please{' '}
              <LinkButton
                verticalAlign="baseline"
                colorScheme="primary"
                onClick={() =>
                  Sentry.showReportDialog({ eventId: errorEventId })
                }
              >
                report the error to us.
              </LinkButton>
            </Text>
          )}
        </ErrorPageText>

        <Link as={NextLink} href={routes.root} passHref>
          <Button colorScheme="primary">GO TO HOME PAGE</Button>
        </Link>
      </ErrorPageMainContent>
      <ErrorPageImage
        accentColor="#DDF3C9"
        srcSet="/static/images/error-pages/500_img.jpg, /static/images/error-pages/500_imgx2.jpg 2x, /static/images/error-pages/500_imgx3.jpg 3x"
      />
    </ErrorPageLayout>
  );
};

export default Error500;
