import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Menu: ComponentMultiStyleConfig = {
  parts: ['item', 'list'],
  baseStyle: {
    list: {
      boxShadow: 'none',
      minWidth: 'inherit',
      border: 'none',
      borderRadius: 'xl',
      whiteSpace: 'nowrap',
      // Fixes boxShadow disappearing issue
      _before: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        boxShadow: 'menu',
        borderRadius: 'xl',
        pointerEvents: 'none',
        content: '""',
      },
    },
    item: {
      _focus: {
        background: 'none',
      },
      _hover: {
        background: 'primary.50',
      },
      color: 'neutral.800',
      fontSize: 'md',
      fontWeight: 500,
    },
  },
};
