import { Icon, IconProps } from '@chakra-ui/react';

/**
 * TODO: In the future this should be updated in the YoutubeIcon
 * Note: This is used in the ManagedCreatorCard, we need a new
 * IconBox component to simulate the Icon styling in ManagedCreatorCard
 * and unify all social icons with this style.
 */
export const YoutubeNoBorderIcon: React.FC<IconProps> = props => (
  <Icon
    data-testid="youtube-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1_241)">
      <path
        d="M29.4025 7.73182C29.2329 7.09359 28.8986 6.5111 28.4332 6.04264C27.9677 5.57419 27.3874 5.2362 26.7502 5.06251C24.4048 4.43182 15.0298 4.43182 15.0298 4.43182C15.0298 4.43182 5.65479 4.43182 3.30933 5.06251C2.6722 5.2362 2.09186 5.57419 1.62639 6.04264C1.16093 6.5111 0.826667 7.09359 0.657058 7.73182C0.0297852 10.0875 0.0297852 15 0.0297852 15C0.0297852 15 0.0297852 19.9125 0.657058 22.2682C0.826667 22.9064 1.16093 23.4889 1.62639 23.9574C2.09186 24.4258 2.6722 24.7638 3.30933 24.9375C5.65479 25.5682 15.0298 25.5682 15.0298 25.5682C15.0298 25.5682 24.4048 25.5682 26.7502 24.9375C27.3874 24.7638 27.9677 24.4258 28.4332 23.9574C28.8986 23.4889 29.2329 22.9064 29.4025 22.2682C30.0298 19.9125 30.0298 15 30.0298 15C30.0298 15 30.0298 10.0875 29.4025 7.73182Z"
        fill="#FF0302"
      />
      <path
        d="M11.9617 19.4608V10.5392L19.8026 15L11.9617 19.4608Z"
        fill="#FEFEFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_241">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
