import { Link as CLink, LinkProps as CLinkProps } from '@chakra-ui/react';
import * as React from 'react';

const Link = React.forwardRef<HTMLAnchorElement, CLinkProps>(
  ({ href, ...props }, ref) => {
    return (
      <CLink
        color="primary.600"
        fontWeight="medium"
        href={href}
        ref={ref}
        {...props}
      />
    );
  }
);

Link.displayName = 'Link';

export default Link;
