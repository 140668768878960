import { Icon, IconProps } from '@chakra-ui/react';

/**
 * TODO: In the future this should be updated in the TiktokIcon
 * Note: This is used in the ManagedCreatorCard, we need a new
 * IconBox component to simulate the Icon styling in ManagedCreatorCard
 * and unify all social icons with this style.
 */
export const TiktokNoBorderIcon: React.FC<IconProps> = props => (
  <Icon
    data-testid="tiktok-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <path
      d="M21.4708 10.8294C23.3999 12.2133 25.7633 13.0276 28.3158 13.0276V8.09829C27.8328 8.0984 27.3509 8.04784 26.8783 7.94736V11.8274C24.326 11.8274 21.963 11.0131 20.0332 9.62928V19.6886C20.0332 24.7208 15.9684 28.7999 10.9543 28.7999C9.0835 28.7999 7.34463 28.2323 5.90015 27.2588C7.54879 28.9505 9.84794 30 12.3916 30C17.4059 30 21.471 25.9209 21.471 20.8885V10.8294H21.4708V10.8294ZM23.2441 5.85627C22.2582 4.77531 21.6108 3.37837 21.4708 1.834V1.19998H20.1085C20.4514 3.16288 21.621 4.83987 23.2441 5.85627ZM9.07151 23.3973C8.52066 22.6725 8.22299 21.7858 8.22432 20.8741C8.22432 18.5726 10.0836 16.7064 12.3774 16.7064C12.8049 16.7063 13.2298 16.772 13.6372 16.9017V11.8622C13.1611 11.7968 12.6806 11.769 12.2003 11.7792V15.7017C11.7926 15.5719 11.3675 15.506 10.9399 15.5065C8.64607 15.5065 6.78691 17.3724 6.78691 19.6742C6.78691 21.3018 7.71623 22.7109 9.07151 23.3973Z"
      fill="#FF004F"
    />
    <path
      d="M20.0332 9.62918C21.9629 11.013 24.3259 11.8273 26.8783 11.8273V7.94725C25.4536 7.6427 24.1923 6.89553 23.244 5.85627C21.6208 4.83977 20.4514 3.16278 20.1085 1.19998H16.5302V20.8883C16.5221 23.1835 14.666 25.042 12.3771 25.042C11.0283 25.042 9.83005 24.3968 9.07115 23.3973C7.71598 22.7109 6.78666 21.3017 6.78666 19.6743C6.78666 17.3727 8.64582 15.5066 10.9396 15.5066C11.3791 15.5066 11.8027 15.5752 12.2001 15.7018V11.7793C7.27414 11.8814 3.3125 15.9207 3.3125 20.8884C3.3125 23.3683 4.29904 25.6163 5.9002 27.2589C7.34468 28.2323 9.08356 28.8 10.9544 28.8C15.9685 28.8 20.0333 24.7207 20.0333 19.6886V9.62918H20.0332Z"
      fill="black"
    />
    <path
      d="M26.8782 7.94725V6.89811C25.5934 6.90006 24.3339 6.53899 23.2439 5.85617C24.2088 6.91633 25.4794 7.64733 26.8782 7.94725ZM20.1084 1.19998C20.0757 1.01239 20.0505 0.823564 20.0331 0.634017V0H15.0925V19.6885C15.0846 21.9835 13.2287 23.842 10.9395 23.842C10.2675 23.842 9.63298 23.6819 9.07106 23.3974C9.82996 24.3968 11.0282 25.0419 12.3771 25.0419C14.6658 25.0419 16.5221 23.1836 16.5301 20.8884V1.19998H20.1084ZM12.2002 11.7793V10.6624C11.7873 10.6057 11.3711 10.5773 10.9544 10.5775C5.93989 10.5774 1.875 14.6567 1.875 19.6885C1.875 22.8432 3.47258 25.6233 5.90021 27.2587C4.29905 25.6162 3.31251 23.368 3.31251 20.8883C3.31251 15.9207 7.27404 11.8814 12.2002 11.7793Z"
      fill="#00F2EA"
    />
  </Icon>
);
