import {
  Auth,
  CheckEmailStatusParams,
  CheckEmailStatusResponse,
  CheckForgotPasswordTokenParams,
  ErrorResponse,
  LoginParams,
  LoginResponse,
  PlatformsAuthUrlParams,
  PlatformsAuthUrlResponse,
  ResetPasswordConfirmParams,
  ResetPasswordParams,
  SignUpEchoCreatorWithPasswordParams,
  SignUpEchoCreatorWithPlatformParams,
  SignUpFoamCreatorWithPasswordParams,
  SignUpFoamCreatorWithPlatformParams,
  SignUpFoamManagedCreatorWithPasswordParams,
  SignUpFoamManagedCreatorWithPlatformParams,
  SignUpFoamTalentRepCreatorWithPasswordParams,
  SignUpFoamTalentRepWithPlatformParams,
  SignupResponse,
  VerifyEmailParams,
} from '@orca/api-auth';
import { useRouter } from 'next/router';
import { useReCaptcha } from 'next-recaptcha-v3';
import * as React from 'react';
import { ReactNode } from 'react';

import { ORIGIN } from '~/constants';
import { accessTokenKey, refreshTokenKey } from '~/constants/local_storage';
import { PLATFORMS } from '~/constants/platforms';
import { routes } from '~/constants/routes';
import { SIGNUP_ROLES } from '~/constants/signUp';
import { USER_ROLES } from '~/constants/user_roles';
import {
  getAlertEmailStatus,
  getAlertFeedBackError,
} from '~/contexts/alert/alertFeedBackError';
import { AuthTokens } from '~/types/AuthTokens';
import { captureException } from '~/types/sentry';
import { fetchSettings } from '~/utils/fetchSettings';
import { getEmailFromAccessToken } from '~/utils/getEmailFromAccessToken';
import { getMainRolUser as getMainRoleUser } from '~/utils/getMainRolUser';
import { getTargetUrl } from '~/utils/getTargetUrl';
import { getUrlSearchParams } from '~/utils/getUrlSearchParams';
import { getUserIdFromAccessToken } from '~/utils/getUserIdFromAccessToken';
import { hasEmailVerified } from '~/utils/hasEmailVerified';

import { AlertProps } from '../alert/useAlert';

import {
  SimpleCheckEmailStatusParams,
  SimpleSendVerifyEmailParams,
  SimpleSignUpEchoCreatorWithPasswordParams,
  SimpleSignUpEchoCreatorWithPlatformParams,
  SimpleSignUpFoamCreatorWithPasswordParams,
  SimpleSignUpFoamCreatorWithPlatformParams,
  SimpleSignUpFoamManagedCreatorWithPasswordParams,
  SimpleSignUpFoamManagedCreatorWithPlatformParams,
  SimpleSignUpFoamTalentRepWithPasswordParams,
  SimpleSignUpFoamTalentRepWithPlatformParams,
} from './useAuth.vm';

interface AuthContextInterface {
  login: (
    params: Omit<LoginParams, 'recaptcha'>,
    queryParams?: object,
    redirectUrl?: string
  ) => Promise<void>;
  logout: (platform: PLATFORMS) => Promise<void>;
  platformsAuthUrl: (
    params: PlatformsAuthUrlParams
  ) => Promise<ErrorResponse | PlatformsAuthUrlResponse>;
  loginWithSSOAccount: (
    params: AuthTokens,
    queryParams?: object,
    redirectUrl?: string
  ) => Promise<void>;
  signUpFoamCreatorWithPassword: (
    params: SimpleSignUpFoamCreatorWithPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => Promise<void>;
  signUpFoamCreatorWithSSO: (
    params: SimpleSignUpFoamCreatorWithPlatformParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => Promise<void>;
  signUpEchoCreatorWithPassword: (
    params: SimpleSignUpEchoCreatorWithPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => Promise<void>;
  signUpEchoCreatorWithSSO: (
    params: SimpleSignUpEchoCreatorWithPlatformParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => Promise<void>;
  signUpFoamManagedCreatorWithPassword: (
    params: SimpleSignUpFoamManagedCreatorWithPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => Promise<void>;
  signUpFoamManagedCreatorWithSSO: (
    params: SimpleSignUpFoamManagedCreatorWithPlatformParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => Promise<void>;
  signUpFoamTalentRepWithPassword: (
    params: SimpleSignUpFoamTalentRepWithPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => Promise<void>;
  signUpFoamTalentRepWithSSO: (
    params: SimpleSignUpFoamTalentRepWithPlatformParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => Promise<void>;
  signUpAutoLogin: (params: Omit<LoginParams, 'recaptcha'>) => Promise<void>;
  resetPasswordRequest: (
    params: ResetPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => Promise<void>;
  resetPasswordConfirm: (params: ResetPasswordConfirmParams) => Promise<void>;
  checkForgotPasswordToken: (
    params: CheckForgotPasswordTokenParams
  ) => Promise<void>;
  sendVerifyEmail: (params: SimpleSendVerifyEmailParams) => Promise<void>;
  role: USER_ROLES;
  accessToken: string;
  refreshToken: string;
  redirectToApp: (
    tokens: AuthTokens,
    queryParams?: object,
    redirectUrl?: string
  ) => Promise<void>;
  verifyEmail: (params: VerifyEmailParams) => Promise<void>;
  checkEmailExists: (
    params: SimpleCheckEmailStatusParams
  ) => Promise<CheckEmailStatusResponse>;
  user: User;
  platform: PLATFORMS;
}

const AuthContext = React.createContext<AuthContextInterface | null>(null);

interface AuthProviderProps {
  children: ReactNode;
  platform: PLATFORMS;
}

interface User {
  id: string;
  role: string;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({
  platform,
  ...rest
}) => {
  const [user, setUser] = React.useState<User>({ id: '', role: '' });
  const { executeRecaptcha } = useReCaptcha();
  const router = useRouter();

  const persistTokens = (tokens: AuthTokens) => {
    window.localStorage.setItem(accessTokenKey, tokens.accessToken);
    window.localStorage.setItem(refreshTokenKey, tokens.refreshToken);
  };

  const redirectToApp = (
    tokens: AuthTokens,
    queryParams?: object,
    redirectUrl?: string
  ) => {
    persistTokens(tokens);
    const params: URLSearchParams = new URLSearchParams({
      accessToken: tokens.accessToken,
      refreshToken: tokens.refreshToken,
      ...queryParams,
    });

    const mainRole = getMainRoleUser(tokens.accessToken, platform);
    if (
      mainRole === USER_ROLES.brandUser &&
      !hasEmailVerified(tokens.accessToken)
    ) {
      router.push({
        pathname: routes.echo.verifyEmail,
        query: { email: getEmailFromAccessToken(tokens.accessToken) },
      });
    } else {
      const targetUrl = getTargetUrl(
        mainRole,
        getUrlSearchParams().get('redirectUrl') || redirectUrl,
        params
      );

      window.location.replace(targetUrl);
    }
  };

  const logout = async (platform: PLATFORMS) => {
    window.localStorage.removeItem(accessTokenKey);
    window.localStorage.removeItem(refreshTokenKey);
    router.push(routes[platform].login);
  };

  const login = async (
    params: Omit<LoginParams, 'recaptcha'>,
    queryParams?: object,
    redirectUrl?: string
  ) => {
    const recaptchaToken = await executeRecaptcha('login');

    const resp: any = await Auth.login({
      ...params,
      recaptcha: recaptchaToken,
    });
    if (Number.isInteger(resp.errorCode)) {
      throw new Error(resp.errorCode);
    } else {
      const { accessToken, refreshToken } = resp as LoginResponse;
      setUser({
        id: getUserIdFromAccessToken(accessToken),
        role: getMainRoleUser(accessToken, platform),
      });
      redirectToApp({ accessToken, refreshToken }, queryParams, redirectUrl);
    }
  };

  // Private
  const signUp = async (
    params: Omit<
      | SignUpFoamCreatorWithPasswordParams
      | SignUpFoamCreatorWithPlatformParams
      | SignUpEchoCreatorWithPasswordParams
      | SignUpEchoCreatorWithPlatformParams
      | SignUpFoamManagedCreatorWithPasswordParams
      | SignUpFoamManagedCreatorWithPlatformParams
      | SignUpFoamTalentRepCreatorWithPasswordParams
      | SignUpFoamTalentRepWithPlatformParams,
      'recaptcha'
    >,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => {
    const recaptchaToken = await executeRecaptcha('signUp');
    const resp: any = await Auth.signUp({
      ...params,
      recaptcha: recaptchaToken,
    } as any);
    if (Number.isInteger(resp.errorCode)) {
      const error = resp as any;
      if (error.errorCode === 103) {
        getAlertEmailStatus(router, platform, setAlertProps, true);
      } else {
        getAlertFeedBackError(error, setAlertProps);
      }
    } else {
      const { accessToken, refreshToken } = resp as SignupResponse;
      setUser({
        id: getUserIdFromAccessToken(accessToken),
        role: getMainRoleUser(accessToken, platform),
      });
      // endpoint call to /settings for avoiding the 409 due to a race condition during signup
      await fetchSettings(accessToken).finally(() =>
        redirectToApp({ accessToken, refreshToken })
      );
    }
  };

  const signUpFoamCreatorWithPassword = async (
    params: SimpleSignUpFoamCreatorWithPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) =>
    signUp(
      {
        ...params,
        origin: ORIGIN.foam,
        role: SIGNUP_ROLES.creator,
      } as SignUpFoamCreatorWithPasswordParams,
      setAlertProps
    );

  const signUpFoamCreatorWithSSO = async (
    params: SimpleSignUpFoamCreatorWithPlatformParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) =>
    signUp(
      {
        ...params,
        origin: ORIGIN.foam,
        role: SIGNUP_ROLES.creator,
      } as SignUpFoamCreatorWithPlatformParams,
      setAlertProps
    );

  const signUpEchoCreatorWithPassword = async (
    params: SimpleSignUpEchoCreatorWithPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) =>
    signUp(
      {
        ...params,
        origin: ORIGIN.echo,
        role: SIGNUP_ROLES.creator,
      } as SignUpEchoCreatorWithPasswordParams,
      setAlertProps
    );

  const signUpEchoCreatorWithSSO = async (
    params: SimpleSignUpEchoCreatorWithPlatformParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) =>
    signUp(
      {
        ...params,
        origin: ORIGIN.echo,
        role: SIGNUP_ROLES.creator,
      } as SignUpEchoCreatorWithPlatformParams,
      setAlertProps
    );

  const signUpFoamManagedCreatorWithPassword = async (
    params: SimpleSignUpFoamManagedCreatorWithPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) =>
    signUp(
      {
        ...params,
        origin: ORIGIN.foam,
        role: SIGNUP_ROLES.creatorManaged,
      } as SignUpFoamManagedCreatorWithPasswordParams,
      setAlertProps
    );

  const signUpFoamManagedCreatorWithSSO = async (
    params: SimpleSignUpFoamManagedCreatorWithPlatformParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) =>
    signUp(
      {
        ...params,
        origin: ORIGIN.foam,
        role: SIGNUP_ROLES.creatorManaged,
      } as SignUpFoamManagedCreatorWithPlatformParams,
      setAlertProps
    );

  const signUpFoamTalentRepWithPassword = async (
    params: SimpleSignUpFoamTalentRepWithPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) =>
    signUp(
      {
        ...params,
        origin: ORIGIN.foam,
        role: SIGNUP_ROLES.rep,
      } as SignUpFoamTalentRepCreatorWithPasswordParams,
      setAlertProps
    );

  const signUpFoamTalentRepWithSSO = async (
    params: SimpleSignUpFoamTalentRepWithPlatformParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => {
    return signUp(
      {
        ...params,
        origin: ORIGIN.foam,
        role: SIGNUP_ROLES.rep,
      } as SignUpFoamTalentRepWithPlatformParams,
      setAlertProps
    );
  };

  const signUpAutoLogin = async (params: Omit<LoginParams, 'r'>) => {
    const recaptcha = await executeRecaptcha('signUpAutoLogin');
    const resp: any = await Auth.login({ ...params, recaptcha });
    if (Number.isInteger(resp.errorCode)) {
      throw new Error(resp.errorCode);
    } else {
      const { accessToken, refreshToken } = resp as LoginResponse;
      setUser({
        id: getUserIdFromAccessToken(accessToken),
        role: getMainRoleUser(accessToken, platform),
      });
      redirectToApp({ accessToken, refreshToken });
    }
  };

  const platformsAuthUrl = async (params: PlatformsAuthUrlParams) => {
    const resp: any = await Auth.platformsAuthUrl(params);
    if (Number.isInteger(resp.errorCode)) {
      throw new Error(resp.errorCode);
    } else {
      return resp as PlatformsAuthUrlResponse;
    }
  };

  const loginWithSSOAccount = (
    tokens: AuthTokens,
    queryParams?: object,
    redirectUrl?: string
  ) => {
    setUser({
      id: getUserIdFromAccessToken(tokens.accessToken),
      role: getMainRoleUser(tokens.accessToken, platform),
    });
    redirectToApp(tokens, queryParams, redirectUrl);
  };

  const resetPasswordRequest = async (
    params: ResetPasswordParams,
    setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>
  ) => {
    const resp: any = await Auth.resetPasswordRequest(params);
    if (Number.isInteger(resp.errorCode)) {
      const error = resp;
      getAlertFeedBackError(error, setAlertProps);
      throw new Error(resp.errorCode.toString());
    }
  };

  const resetPasswordConfirm = async (params: ResetPasswordConfirmParams) => {
    const resp: any = await Auth.resetPasswordConfirm(params);
    if (Number.isInteger(resp.errorCode)) {
      throw new Error(resp.errorCode);
    } else {
      const { accessToken, refreshToken } = resp as LoginResponse;
      redirectToApp({ accessToken, refreshToken });
    }
  };

  const checkForgotPasswordToken = async (
    params: CheckForgotPasswordTokenParams
  ) => {
    const resp: any = await Auth.checkForgotPasswordToken(params);
    if (Number.isInteger(resp.errorCode)) {
      throw new Error(resp.errorCode);
    }
  };

  const sendVerifyEmail = async (params: SimpleSendVerifyEmailParams) => {
    const recaptchaToken = await executeRecaptcha('sendVerifyEmail');

    await Auth.sendVerifyEmail({
      ...params,
      recaptcha: recaptchaToken,
    });
  };

  const verifyEmail = async (params: VerifyEmailParams) => {
    const resp: any = await Auth.verifyEmail(params);

    if (Number.isInteger(resp.errorCode)) {
      // This CaptureException triggers when a user tries to reuse a validation link (that includes a token)
      captureException(
        new Error('Attempt to validate an email with a token already used'),
        { level: 'info' }
      );
      router.push(routes[platform].login);
    } else {
      const { accessToken, refreshToken } = resp as LoginResponse;
      redirectToApp({ accessToken, refreshToken }, { emailValidated: true });
    }
  };

  const checkEmailExists = async (params: CheckEmailStatusParams) => {
    const recaptchaToken = await executeRecaptcha('checkEmailExists');
    const resp: any = await Auth.checkEmailStatus({
      ...params,
      recaptcha: recaptchaToken,
    });
    if (Number.isInteger(resp.errorCode) && resp.errorCode === 102) {
      throw new Error(resp.errorCode);
    } else {
      return resp;
    }
  };

  const providerValue = {
    login,
    logout,
    platformsAuthUrl,
    loginWithSSOAccount,
    signUpFoamCreatorWithPassword,
    signUpFoamCreatorWithSSO,
    signUpEchoCreatorWithPassword,
    signUpEchoCreatorWithSSO,
    signUpFoamManagedCreatorWithPassword,
    signUpFoamManagedCreatorWithSSO,
    signUpFoamTalentRepWithPassword,
    signUpFoamTalentRepWithSSO,
    signUpAutoLogin,
    resetPasswordRequest,
    resetPasswordConfirm,
    checkForgotPasswordToken,
    sendVerifyEmail,
    redirectToApp,
    verifyEmail,
    checkEmailExists,
    platform,
    user,
  } as AuthContextInterface;
  return <AuthContext.Provider value={providerValue} {...rest} />;
};

export const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
};
