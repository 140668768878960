import { ComponentWithAs, Icon, IconProps } from '@chakra-ui/react';

export const CloseNarrowIcon: ComponentWithAs<'svg', IconProps> = props => (
  <Icon
    boxSize="24px"
    data-testing="close narrow icon"
    fill="none"
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      d="m8.464 15.535 7.071-7.07M8.464 8.465l7.071 7.07"
      stroke="currentColor"
      strokeLinecap="round"
      strokeWidth="1.5"
    />
  </Icon>
);
