import { AlertProps as AlertChakraProps, AlertStatus } from '@chakra-ui/react';
import React, { ReactNode, useState } from 'react';

export interface AlertProps extends AlertChakraProps {
  title?: string;
  description?: JSX.Element;
  status?: AlertStatus;
  isVisible?: boolean;
}

export const defaultValueAlerts: AlertProps = {
  isVisible: false,
  title: '',
  status: 'info',
  description: <></>,
};

type AlertContextType = {
  alertProps: AlertProps;
  setAlertProps: React.Dispatch<React.SetStateAction<AlertProps>>;
};

const alertContextState = {
  alertProps: defaultValueAlerts,
  setAlertProps: () => {},
};

export const AlertContext =
  React.createContext<AlertContextType>(alertContextState);

interface AlertProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<AlertProviderProps> = props => {
  const [alertProps, setAlertProps] = useState<AlertProps>(defaultValueAlerts);

  return (
    <AlertContext.Provider value={{ alertProps, setAlertProps }} {...props} />
  );
};

export const useAlert = () => {
  const context = React.useContext(AlertContext);

  if (!context) {
    throw new Error('useAlert must be used within AlertProvider');
  }

  return context;
};
