import { Heading } from '@chakra-ui/react';

import { ChildrenType } from '~/types';

export function ErrorPageHeading({ children }: ChildrenType) {
  return (
    <Heading fontSize="6xl" fontWeight="normal" mb={4}>
      {children}
    </Heading>
  );
}
