import NHead from 'next/head';

const Head = () => (
  <NHead>
    <title>Powered by Whalar</title>
    <link
      rel="icon"
      href="/static/favicon/favicon.svg?v=5"
      type="image/svg+xml"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/static/favicon/apple-touch-icon.png?v=5"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="32x32"
      href="/static/favicon/favicon-32x32.png?v=5"
    />
    <link
      rel="icon"
      type="image/png"
      sizes="16x16"
      href="/static/favicon/favicon-16x16.png?v=5"
    />
    <link rel="manifest" href="/static/favicon/site.webmanifest?v=5" />
    <link
      rel="mask-icon"
      href="/static/favicon/safari-pinned-tab.svg?v=5"
      color="#1c2128"
    />
    <link rel="shortcut icon" href="/static/favicon/favicon.ico?v=5" />
    <meta name="apple-mobile-web-app-title" content="Foam " />
    <meta name="application-name" content="Foam " />
    <meta name="msapplication-TileColor" content="#2d89ef" />
    <meta
      name="msapplication-config"
      content="/static/favicon/browserconfig.xml?v=5"
    />
    <meta name="theme-color" content="#ffffff" />
    <meta name="robots" content="noindex,nofollow" />
  </NHead>
);

export default Head;
