import { Box, Flex } from '@chakra-ui/react';

import { Footer } from '../Footer';

interface ErrorPageLayoutProps {
  children: React.ReactNode;
}

export function ErrorPageLayout({ children }: ErrorPageLayoutProps) {
  return (
    <Flex
      height={{ md: '100%' }}
      minH="100vh"
      flexDirection="column"
      position="relative"
    >
      <Box flex="1">
        <Box
          px={{ base: 6, md: 24 }}
          pr={{ base: 6, md: 'calc(50% - 6rem)' }}
          py={{ base: 12, md: 40 }}
          h="100%"
        >
          {children}
        </Box>
      </Box>
      <Footer marginTop={{ md: 20, lg: 20 }} />
    </Flex>
  );
}
