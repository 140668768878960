import axios from 'axios';
// params are fixed, as we just want the success of this call before redirecting to foam
// for avoiding the 409 due to a race condition during signup
export const fetchSettings = (token: string | string[] | undefined) =>
  axios.get(
    `${process.env.NEXT_PUBLIC_FOAM_API_RESOURCE}/api/user/settings?page=1&pageSize=10`,
    {
      headers: {
        'X-AUTH-TOKEN': token,
      },
    }
  );
