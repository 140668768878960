import jwt_decode from 'jwt-decode';

import { PLATFORMS } from '~/constants/platforms';
import { USER_ROLES, USER_ROLES_FOAM_PRIORITARY } from '~/constants/user_roles';
import { JsonTokenDecoded } from '~/types/JsonTokenDecoded';

export const getMainRolUser = (accessToken: string, platform: PLATFORMS) => {
  const decoded: JsonTokenDecoded = jwt_decode(accessToken);

  const orderedRoleList =
    platform === PLATFORMS.echo
      ? Object.keys(USER_ROLES)
      : USER_ROLES_FOAM_PRIORITARY;

  return USER_ROLES[
    (orderedRoleList.find(key =>
      decoded.realm_access.roles.includes(
        USER_ROLES[key as keyof typeof USER_ROLES]
      )
    ) as keyof typeof USER_ROLES) || USER_ROLES.anonymous
  ];
};
