import { Box, BoxProps } from '@chakra-ui/react';
import { FC } from 'react';

interface ErrorPageImageProps extends BoxProps {
  srcSet: string;
  accentColor: string;
}

export const ErrorPageImage: FC<ErrorPageImageProps> = ({
  srcSet,
  accentColor,
  ...props
}) => {
  return (
    <Box position="absolute" right="10px" top="10%" zIndex="hide">
      <Box
        as="img"
        srcSet={srcSet}
        alt="Error"
        rounded="full"
        position="absolute"
        top="60%"
        right="50%"
        d={{ base: 'none', md: 'block' }}
        {...props}
      />
      <Box rounded="full" backgroundColor={accentColor} h="30px" w="30px" />
      <Box rounded="full" backgroundColor={accentColor} h="440px" w="440px" />
    </Box>
  );
};
