import { Box, Flex, Link } from '@chakra-ui/react';
import NextLink from 'next/link';

import { routes } from '~/constants/routes';

import { BoxStyles, LinkStyles } from '../Footer.styles';

import { CONTACT_EMAIL } from './FooterMenu.costants';

export const FooterMenu: React.FC = () => {
  return (
    <Box display="inline-flex" paddingRight={16} {...BoxStyles}>
      <Flex alignItems="flex-end">
        <Link
          as={NextLink}
          target="_blank"
          href={routes.foam.termsAndConditions}
          passHref
          marginLeft="auto"
          {...LinkStyles}
        >
          Website Terms
        </Link>

        <Link
          as={NextLink}
          target="_blank"
          href={routes.foam.privacyPolicy}
          passHref
          marginLeft={8}
          {...LinkStyles}
        >
          Privacy Policy
        </Link>

        <Link marginLeft={8} href={`mailto:${CONTACT_EMAIL}`} {...LinkStyles}>
          Contact
        </Link>
      </Flex>
    </Box>
  );
};
