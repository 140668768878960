import { noop } from 'lodash';
import React, { useEffect } from 'react';

import { ChildrenType } from '~/types';

import { INTERCOM_EVENTS } from './IntercomContext.constants';

declare const window: any;

interface Context {
  shutdown: () => void;
}

export const IntercomContext = React.createContext<Context>({
  shutdown: noop,
});

export const IntercomProvider: React.FC<ChildrenType> = ({ children }) => {
  const initializeIntercom = React.useCallback(() => {
    if (typeof window !== 'undefined' && window!.Intercom) {
      window!.Intercom(INTERCOM_EVENTS.boot, {
        app_id: process.env.NEXT_PUBLIC_INTERCOM_APP_ID ?? '',
        Registered: false,
      });
    }
  }, []);

  useEffect(() => {
    initializeIntercom();
  }, [initializeIntercom]);

  const shutdown = () => {
    if (typeof window === 'undefined' && window!.Intercom) {
      window!.Intercom(INTERCOM_EVENTS.shutdown);
    }
  };

  return (
    <IntercomContext.Provider
      value={{
        shutdown,
      }}
    >
      {children}
    </IntercomContext.Provider>
  );
};
