// the order is important to mapping main rol
export enum USER_ROLES {
  anonymous = 'anonymous',
  backofficeAdmin = 'backoffice-admin',
  backofficeWriter = 'backoffice-write',
  backofficeReader = 'backoffice-read',
  agency = 'echo-agency',
  brand = 'echo-manager',
  brandUser = 'echo-brand-user',
  creatorEcho = 'echo-creator',
  creatorManaged = 'foam-managed-creator',
  creatorFoam = 'foam-creator',
  talentRep = 'foam-manager',
}

export const USER_ROLES_FOAM_PRIORITARY = [
  'anonymous',
  'talentRep',
  'creatorManaged',
  'creatorFoam',
  'backofficeAdmin',
  'backofficeWriter',
  'backofficeReader',
  'agency',
  'brand',
  'brandUser',
  'creatorEcho',
];
