import { ChakraProvider } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import { ReCaptchaProvider } from 'next-recaptcha-v3';
import { FC, useMemo } from 'react';

import { HeapIdentifier } from '~/components/HeapIdentifier';
import { PLATFORMS } from '~/constants/platforms';
import { IntercomProvider } from '~/contexts/Intercom/IntercomContext';
import { AlertProvider } from '~/contexts/alert';
import { AuthProvider, useAuth } from '~/contexts/auth';
import { SignUpProvider } from '~/contexts/signup';
import customTheme from '~/styles/theme';
import { ChildrenType } from '~/types';
import { Sentry } from '~/types/sentry';

import { GlobalErrorBoundary } from '../GlobalErrorBoundary';

const AppProviders: FC<ChildrenType> = ({ children }) => {
  const { pathname } = useRouter();

  const platform = useMemo(() => {
    const subpath = pathname?.split('/')[1];
    return subpath === 'whalar' ? PLATFORMS.echo : PLATFORMS.foam;
  }, [pathname]);

  function SentryUserManager() {
    const { user } = useAuth();
    if (user) {
      Sentry.setUser({ id: user.id });
    }
    return null;
  }

  return (
    <ChakraProvider theme={customTheme}>
      <IntercomProvider>
        <GlobalErrorBoundary>
          <ReCaptchaProvider
            reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
          >
            <AlertProvider>
              {platform && (
                <AuthProvider platform={platform}>
                  <SentryUserManager />
                  <HeapIdentifier />
                  <SignUpProvider>{children}</SignUpProvider>
                </AuthProvider>
              )}
            </AlertProvider>
          </ReCaptchaProvider>
        </GlobalErrorBoundary>
      </IntercomProvider>
    </ChakraProvider>
  );
};

export default AppProviders;
