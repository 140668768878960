import { useEffect } from 'react';

import { useAuth } from '~/contexts/auth';

export const HeapIdentifier = () => {
  const auth = useAuth();
  const setHeapUserIdentifier = (id: string) => {
    window.heap?.identify(id);
  };

  useEffect(() => {
    if (auth.user) {
      setHeapUserIdentifier(auth.user.id);
    }
  }, [auth.user]);

  return null;
};
