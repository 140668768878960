import { Button, ButtonProps } from '@chakra-ui/react';
import * as React from 'react';

const LinkButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (props, ref) => (
    <Button
      ref={ref}
      textTransform="initial"
      letterSpacing="normal"
      fontSize="md"
      fontWeight="medium"
      {...props}
      variant="link"
    />
  )
);

LinkButton.displayName = 'LinkButton';
LinkButton.propTypes = Button.propTypes;
LinkButton.defaultProps = Button.defaultProps;

export default LinkButton;
