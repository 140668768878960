import { Box } from '@chakra-ui/react';
import { FC } from 'react';

import { ChildrenType } from '~/types';

export const ErrorPageText: FC<ChildrenType> = ({ children }) => {
  return (
    <Box
      backgroundColor="gray.50"
      px={5}
      py={4}
      fontSize="md"
      rounded="lg"
      mb={6}
    >
      {children}
    </Box>
  );
};
