export const USER_DOES_NOT_EXIST_ERROR_TITLE =
  "The email doesn't exist in our system";
export const USER_EXIST_ERROR_TITLE =
  'An account for this email already exists';
export const CONTACT_EMAIL = 'hello@foam.io';
export const WHALAR_CONTACT_EMAIL = 'support@whalar.com';

export enum CONNECT_PLATFORMS_ACTIONS {
  login = 'login',
  signup = 'signup',
}

export enum ORIGIN {
  foam = 'foam',
  echo = 'echo',
}
