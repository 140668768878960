import { ComponentMultiStyleConfig /* theme */ } from '@chakra-ui/react';

export const Modal: ComponentMultiStyleConfig = {
  parts: [
    'body',
    'closeButton',
    'dialog',
    'dialogContainer',
    'footer',
    'header',
    'overlay',
  ],
  /*
   * Note: this shouldn't be in a `variant`, but in `baseStyle` and globally
   * applied to any `Modal`. But we can't do this right now and we are forced
   * at the moment to just use it locally, for
   * `MediaPackDetailSelectContentModal` (used in new Media Pack design).
   * But this is prepared here to eventually use it globally
   * (moving it where we already said).
   */
  variants: {
    newMP: {
      body: {
        '&:first-of-type': {
          marginTop: '2',
          paddingTop: '0',
        },
        '&:last-child': { marginBottom: '0' },
        flexBasis: 'auto',
        height: '100%',
        marginBottom: '6',
        padding: '0 0.5rem 0 0',
      },
      closeButton: {
        borderRadius: '50%',
        height: '6',
        width: '6',
        '&:first-child': {
          alignSelf: 'end',
          position: 'relative',
          right: '0',
          top: '0',
        },
        '&:hover': {
          backgroundColor: 'primary.50',
        },
        '& > svg': {
          height: '0.625rem',
          width: '0.625rem',
        },
      },
      dialog: {
        alignSelf: { base: 'flex-end', md: 'center' },
        borderBottomLeftRadius: { base: '0', md: 'xl' },
        borderBottomRightRadius: { base: '0', md: 'xl' },
        borderTopLeftRadius: 'xl',
        borderTopRightRadius: 'xl',
        marginBottom: '0',
        maxHeight: { base: '100%', md: 'calc(100vh - 7rem)' },
        padding: '6',
      },
      dialogContainer: {
        height: '100%',
      },
      footer: {
        alignItems: 'center',
        flex: '1 0',
        justifyContent: 'space-between',
        paddingX: '0',
        paddingBottom: '0',
      },
      // Note: only affects `title` prop of our custom ModalHeader component
      header: {
        flex: '1 0',
        fontWeight: 'medium',
        padding: '0',
      },
      overlay: {
        bottom: { base: 0, md: 'initial' },
        top: { base: 'initial', md: 0 },
      },
    },
  },
  /*
   * Note: this should be here, applied to all Modals in a global Modal
   * refactor, but as we can't do this right now, this is only set in local
   * `Modal` clone for `MediaPackDetailSelectContentModal`, for new Media Packs
   * design, via a temporary (ugly) util function.
   */
  /* sizes: {
    xxs: {
      dialog: {
        maxWidth: '100vw',
        width: '224px',
      },
    },
    xs: {
      dialog: {
        maxWidth: '100vw',
        width: '312px',
      },
    },
    sm: {
      dialog: {
        maxWidth: '100vw',
        width: '448px',
      },
    },
    md: {
      dialog: {
        maxWidth: 'calc(100vw - 96px)',
        width: '552px',
      },
    },
    lg: {
      dialog: {
        maxWidth: 'calc(100vw - 96px)',
        width: '662px',
      },
    },
    xl: {
      dialog: {
        maxWidth: 'calc(100vw - 96px)',
        width: '864px',
      },
    },
    '2xl': {
      dialog: {
        maxWidth: 'calc(100vw - 96px)',
        width: '1024px',
      },
    },
    full: {
      dialogContainer: {
        zIndex: theme.zIndices.toast,
      },
      dialog: {
        borderTopLeftRadius: '0',
        borderTopRightRadius: '0',
        height: '100vh',
        marginTop: '0',
        width: '100vw',
      },
    },
    fullWidth: {
      dialog: {
        width: '100vw',
      },
    },
  }, */
};
