var __async = (__this, __arguments, generator) => {
  return new Promise((resolve, reject) => {
    var fulfilled = (value) => {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    };
    var rejected = (value) => {
      try {
        step(generator.throw(value));
      } catch (e) {
        reject(e);
      }
    };
    var step = (x) => x.done ? resolve(x.value) : Promise.resolve(x.value).then(fulfilled, rejected);
    step((generator = generator.apply(__this, __arguments)).next());
  });
};

// src/base.ts
import axios from "axios";
var instance = axios.create({
  baseURL: `${process.env.NEXT_PUBLIC_AUTH_RESOURCE}/v1`
});
function request(method, url, data) {
  return __async(this, null, function* () {
    try {
      const response = yield instance[method](
        url,
        method === "get" ? {
          params: data
        } : data
      );
      return response.data;
    } catch (error) {
      return {
        errorCode: error.response.data.code || error.response.status,
        message: error.response.data.errors && error.response.data.errors[0].message
      };
    }
  });
}
var requests = {
  get(url, data) {
    return __async(this, null, function* () {
      return request("get", url, data);
    });
  },
  post(url, data) {
    return __async(this, null, function* () {
      return request("post", url, data);
    });
  },
  put(url, data) {
    return __async(this, null, function* () {
      return request("put", url, data);
    });
  },
  patch(url, data) {
    return __async(this, null, function* () {
      return request("patch", url, data);
    });
  },
  delete(url, data) {
    return __async(this, null, function* () {
      return request("delete", url, data);
    });
  }
};

// src/constants/routes.ts
var routes = {
  login: "/account/login",
  logout: "/account/logout/",
  signup: "/account/register",
  platformsAuthUrl: "/platforms/redirect-routes/",
  resetPassword: "/account/password/reset/",
  resetPasswordConfirm: "/account/password/reset",
  refreshToken: "/token/refresh",
  impersonate: "/account/impersonate/",
  linkFacebookAccount: "/account/facebook/access",
  linkYoutubeAccount: "/account/youtube/access",
  checkTTCMStatus: "/account/tiktok/creator/status",
  linkTTAccount: "/account/tiktok/access",
  sendTTInvite: "/account/tiktok/invite",
  verifyEmail: "/account/verify-email/",
  sendVerifyEmail: "/account/send-verify-email",
  checkEmailStatus: "account/check-register/",
  updateUserRole: "/account/roles/map",
  checkForgotPasswordToken: "account/password/reset/check/"
};

// src/index.ts
var Auth = {
  login: (payload) => requests.post(routes.login, payload),
  logout: () => requests.post(routes.logout),
  signUp: (payload) => requests.post(routes.signup, payload),
  platformsAuthUrl: (payload) => requests.get(
    routes.platformsAuthUrl,
    payload
  ),
  resetPasswordRequest: (payload) => requests.get(routes.resetPassword, payload),
  resetPasswordConfirm: (payload) => requests.post(
    routes.resetPasswordConfirm,
    payload
  ),
  refreshAccessToken: (payload) => requests.post(
    routes.refreshToken,
    payload
  ),
  impersonate: (payload) => requests.post(
    routes.impersonate,
    payload
  ),
  linkFacebookAccount: (payload) => requests.post(
    routes.linkFacebookAccount,
    payload
  ),
  linkYoutubeAccount: (payload) => requests.get(routes.linkYoutubeAccount, payload),
  checkTiktokCreatorMarketplaceStatus: (payload) => requests.get(
    routes.checkTTCMStatus,
    payload
  ),
  linkTiktokAccount: (payload) => requests.get(routes.linkTTAccount, payload),
  sendTiktokInvite: (payload) => requests.post(routes.sendTTInvite, payload),
  verifyEmail: (payload) => requests.get(
    routes.verifyEmail,
    payload
  ),
  sendVerifyEmail: (payload) => requests.post(routes.sendVerifyEmail, payload),
  checkEmailStatus: (payload) => requests.get(
    routes.checkEmailStatus,
    payload
  ),
  updateUserRole: (payload) => requests.put(
    routes.updateUserRole,
    payload
  ),
  checkForgotPasswordToken: (payload) => requests.get(
    routes.checkForgotPasswordToken,
    payload
  )
};
export {
  Auth
};
