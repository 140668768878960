import { Heading as CHeading } from '@chakra-ui/react';

import { ChildrenType } from '~/types';

export function ErrorPageSubheading({ children }: ChildrenType) {
  return (
    <CHeading as="h2" size="lg" fontWeight="normal" mb={10}>
      {children}
    </CHeading>
  );
}
