import { Icon, IconProps } from '@chakra-ui/react';

/**
 * TODO: In the future this should be updated in the InstagramIcon
 * Note: This is used in the ManagedCreatorCard, we need a new
 * IconBox component to simulate the Icon styling in ManagedCreatorCard
 * and unify all social icons with this style.
 */
export const InstagramNoBorderIcon: React.FC<IconProps> = props => (
  <Icon
    data-testid="instagram-icon"
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_1_21948)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.2895C0 3.7113 3.7113 0 8.2895 0H21.7105C26.2887 0 30 3.7113 30 8.2895V21.7105C30 26.2887 26.2887 30 21.7105 30H8.2895C3.7113 30 0 26.2887 0 21.7105V8.2895ZM8.2895 2.3684C5.0194 2.3684 2.3684 5.0194 2.3684 8.2895V21.7105C2.3684 24.9806 5.0194 27.6316 8.2895 27.6316H21.7105C24.9806 27.6316 27.6316 24.9806 27.6316 21.7105V8.2895C27.6316 5.0194 24.9806 2.3684 21.7105 2.3684H8.2895ZM15 20.5263C18.0521 20.5263 20.5263 18.0521 20.5263 15C20.5263 11.9479 18.0521 9.4737 15 9.4737C11.9479 9.4737 9.4737 11.9479 9.4737 15C9.4737 18.0521 11.9479 20.5263 15 20.5263ZM15 22.8947C19.3601 22.8947 22.8947 19.3601 22.8947 15C22.8947 10.6399 19.3601 7.1053 15 7.1053C10.6399 7.1053 7.1053 10.6399 7.1053 15C7.1053 19.3601 10.6399 22.8947 15 22.8947ZM23.0526 7.7368C23.9247 7.7368 24.6316 7.0299 24.6316 6.1579C24.6316 5.2859 23.9247 4.5789 23.0526 4.5789C22.1806 4.5789 21.4737 5.2859 21.4737 6.1579C21.4737 7.0299 22.1806 7.7368 23.0526 7.7368Z"
        fill="url(#paint0_linear_1_21948)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 8.2895C0 3.7113 3.7113 0 8.2895 0H21.7105C26.2887 0 30 3.7113 30 8.2895V21.7105C30 26.2887 26.2887 30 21.7105 30H8.2895C3.7113 30 0 26.2887 0 21.7105V8.2895ZM8.2895 2.3684C5.0194 2.3684 2.3684 5.0194 2.3684 8.2895V21.7105C2.3684 24.9806 5.0194 27.6316 8.2895 27.6316H21.7105C24.9806 27.6316 27.6316 24.9806 27.6316 21.7105V8.2895C27.6316 5.0194 24.9806 2.3684 21.7105 2.3684H8.2895ZM15 20.5263C18.0521 20.5263 20.5263 18.0521 20.5263 15C20.5263 11.9479 18.0521 9.4737 15 9.4737C11.9479 9.4737 9.4737 11.9479 9.4737 15C9.4737 18.0521 11.9479 20.5263 15 20.5263ZM15 22.8947C19.3601 22.8947 22.8947 19.3601 22.8947 15C22.8947 10.6399 19.3601 7.1053 15 7.1053C10.6399 7.1053 7.1053 10.6399 7.1053 15C7.1053 19.3601 10.6399 22.8947 15 22.8947ZM23.0526 7.7368C23.9247 7.7368 24.6316 7.0299 24.6316 6.1579C24.6316 5.2859 23.9247 4.5789 23.0526 4.5789C22.1806 4.5789 21.4737 5.2859 21.4737 6.1579C21.4737 7.0299 22.1806 7.7368 23.0526 7.7368Z"
        fill="url(#paint1_linear_1_21948)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1_21948"
        x1="-3.3462"
        y1="-4.1538"
        x2="7.8462"
        y2="17.7692"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#D9D9D9" />
        <stop offset="0.0001" stopColor="#1F0CF8" />
        <stop offset="0.868122" stopColor="#ED1389" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1_21948"
        x1="1.0385"
        y1="29.1923"
        x2="13.2692"
        y2="-1.6154"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FC2C46" />
        <stop offset="0.0001" stopColor="#FBAB48" />
        <stop offset="0.39864" stopColor="#FC3746" />
        <stop offset="0.85431" stopColor="#FC2C46" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_1_21948">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
