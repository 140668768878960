import { Box, BoxProps, IconProps } from '@chakra-ui/react';

interface Props extends BoxProps {
  icon: React.ReactElement<IconProps>;
  isRound?: boolean;
}

export const IconBox: React.FC<Props> = ({
  icon,
  isRound = false,
  ...props
}) => (
  <Box
    alignItems="center"
    borderRadius={isRound ? 'full' : 'md'}
    display="inline-flex"
    justifyContent="center"
    padding="0"
    {...props}
  >
    {icon}
  </Box>
);
