import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const Logo: ComponentSingleStyleConfig = {
  sizes: {
    sm: {
      width: '10',
      height: '4',
    },
    md: {
      width: '14',
      height: '5',
    },
    lg: {
      width: '20',
      height: '7',
    },
  },
  defaultProps: {
    size: 'md',
  },
};
