import { ComponentMultiStyleConfig /* theme */ } from '@chakra-ui/react';

export const Popover: ComponentMultiStyleConfig = {
  parts: ['popper'],
  variants: {
    fullWidth: {
      popper: {
        width: '100%',
      },
    },
  },
};
