import { USER_ROLES } from '~/constants/user_roles';
import { getDefaultAppDomain } from '~/utils/getDefaultAppDomain';

import { hasWhalarDomain } from './hasWhalarDomain';

export function getTargetUrl(
  mainRole: USER_ROLES,
  redirectUrl: string | string[] | undefined,
  params: URLSearchParams
): URL {
  let url: URL;

  if (redirectUrl) {
    if (hasWhalarDomain(redirectUrl as string)) {
      url = new URL(redirectUrl as string);
    } else {
      url = new URL(`${getDefaultAppDomain(mainRole)}${redirectUrl}`);
    }
  } else {
    url = new URL(getDefaultAppDomain(mainRole));
  }

  params.forEach((val, key) => {
    url.searchParams.append(key, val);
  });

  return url;
}
