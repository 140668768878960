import { Container, ContainerProps, Flex } from '@chakra-ui/react';

import { FooterCopyright, FooterMenu, FooterPartners } from './components';

export const Footer: React.FC<ContainerProps> = ({ ...props }) => {
  return (
    <Container
      backgroundColor="white"
      as="footer"
      paddingY={4}
      paddingX={8}
      maxWidth="inherit"
      {...props}
    >
      <Flex alignItems="flex-end" flexWrap="wrap">
        <FooterCopyright />
        <FooterPartners marginRight="auto" />
        <FooterMenu />
      </Flex>
    </Container>
  );
};
