import { Icon, IconProps } from '@chakra-ui/react';

export const WhalarLogo: React.FC<IconProps> = props => (
  <Icon
    data-testid="whalar-logo-icon"
    width="33px"
    height="32px"
    viewBox="0 0 33 32"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_2976_458)">
      <path
        d="M32.9843 0.304199V8.54953C32.9843 8.54953 32.9843 12.6334 24.739 12.6334C16.4936 12.6334 16.4936 16.5286 16.4936 16.7172C16.4936 16.5397 16.3161 12.6334 8.24832 12.6334C-0.274452 12.6334 0.00298977 8.54953 0.00298977 8.54953V0.304199C0.00298977 0.304199 0.0029814 4.38802 8.24832 4.38802C16.4936 4.38802 16.4936 8.19447 16.4936 8.4719C16.4936 8.10569 16.5824 4.38802 24.739 4.38802C32.8955 4.38802 32.9843 0.304199 32.9843 0.304199Z"
        fill="currentColor"
      />
      <path
        d="M24.7389 19.2273C16.5823 19.2273 16.4936 23.0338 16.4936 23.3112C16.4936 22.945 16.1273 19.2273 8.24821 19.2273C0.369082 19.2273 0.00287705 15.1435 0.00287705 15.1435V23.3888C0.00287705 23.3888 -0.263468 27.4727 8.24821 27.4727C16.316 27.4727 16.4936 31.3679 16.4936 31.5566C16.4936 31.379 16.6711 27.4727 24.7389 27.4727C32.8066 27.4727 32.9842 23.3888 32.9842 23.3888V15.1435C32.9842 15.0548 32.9842 19.2273 24.7389 19.2273Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_2976_458">
        <rect width="32.8889" height="32" fill="white" />
      </clipPath>
    </defs>
  </Icon>
);
