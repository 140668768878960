import { Box, Text } from '@chakra-ui/react';

import { FoamLogo } from '~/components/FoamLogo';

import { BoxStyles, TextStyles } from '../Footer.styles';

export const FooterCopyright = () => {
  return (
    <Box marginRight={12} {...BoxStyles}>
      <FoamLogo display="inline-block" marginRight={2} color="lightBlack" />
      <Text display="inline-block" {...TextStyles}>
        powered by Whalar
      </Text>
    </Box>
  );
};
