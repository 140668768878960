import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';

import Error500 from '~/components/Error500';
import { ChildrenType } from '~/types';
import { captureException } from '~/types/sentry';

export function GlobalErrorBoundary({ children }: ChildrenType) {
  const [eventId, setEventId] = useState<string | null>(null);

  const handleError = (error: Error, info: { componentStack: string }) => {
    const { message } = error;
    const newEventId = captureException(new Error(message), {
      errorInfo: info,
    });
    setEventId(newEventId);
  };

  return (
    <ErrorBoundary
      fallbackRender={() => <Error500 errorEventId={eventId} />}
      onError={handleError}
    >
      {children}
    </ErrorBoundary>
  );
}
