import React from 'react';

export const usePersistedState = <ValueType>(
  key: string,
  defaultValue: ValueType,
  storage = typeof window === 'undefined' ? undefined : window.localStorage
): [ValueType, React.Dispatch<React.SetStateAction<ValueType>>, () => void] => {
  const storageData = storage?.getItem(key);
  const [state, setState] = React.useState<ValueType>(() =>
    storageData ? JSON.parse(storageData) : defaultValue
  );

  const removeItem = () => {
    storage?.removeItem(key);
  };

  React.useEffect(() => {
    storage?.setItem(key, JSON.stringify(state));
  }, [key, state, storage]);

  return [state, setState, removeItem];
};
