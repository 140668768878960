import { Text } from '@chakra-ui/react';

import { CONTACT_EMAIL } from '~/constants';

import Link from '../Link';

interface Props {
  eventId: string;
}

export const ContactError: React.FC<Props> = ({ eventId }) => (
  <Text>
    Please try again in a few minutes. If the issue persists,{' '}
    <Link
      textDecoration="underline"
      fontWeight="500"
      color="black"
      href={`mailto:${CONTACT_EMAIL}`}
    >
      contact us
    </Link>{' '}
    sharing the following id: {eventId}
  </Text>
);
