import React from 'react';

import { signUpFieldsKey } from '~/constants/local_storage';
import { usePersistedState } from '~/hooks/usePersistedState';
import { ChildrenType } from '~/types';

import { SignUpFields } from './SignupContext.vm';

interface SignUpInterface {
  removeSignUpFields: () => void;
  setFields: (fields: SignUpFields) => SignUpFields;
  fields: SignUpFields;
  isEcho: boolean;
  setIsEcho: (isEcho: boolean) => boolean;
}

export const SignUpContext = React.createContext<SignUpInterface | null>(null);

export const SignUpProvider: React.FC<ChildrenType> = props => {
  const [fields, setFields, removeItem] = usePersistedState<SignUpFields>(
    signUpFieldsKey,
    {}
  );
  const [isEcho, setIsEcho] = usePersistedState('signUpEcho', false);

  const removeSignUpFields = (): void => {
    removeItem();
  };

  const providerValue = {
    removeSignUpFields,
    fields,
    setFields,
    isEcho,
    setIsEcho,
  } as SignUpInterface;
  return (
    <>
      <SignUpContext.Provider value={providerValue} {...props} />
    </>
  );
};

export const useSignUp = () => {
  const context = React.useContext(SignUpContext);

  if (!context) {
    throw new Error('useSignUp must be used within SignUpProvider');
  }

  return context;
};
